var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar-items',{staticClass:"ml-5 align-center"},[_c('div',{staticClass:"d-none d-sm-flex mx-4 text-body-1 text-center menu-button",class:{
			'primary--text font-weight-bold': ['Home'].includes(_vm.routeName),
			'grey--text text--darken-4': ['Home'].includes(_vm.routeName) === false,
		},attrs:{"large":"","text":""},on:{"click":function($event){return _vm.$emit('clickMenu', 'Home')}}},[_vm._v(" Why Tella ")]),_vm._l((_vm.menuList),function(menu){return _c('div',{key:menu.routeName,staticClass:"d-none d-sm-flex mx-4 text-body-1 text-center menu-button",class:{
			'primary--text font-weight-bold': menu.activeList.includes(_vm.routeName),
			'grey--text text--darken-4':
				menu.activeList.includes(_vm.routeName) === false,
		},attrs:{"large":"","text":""},on:{"click":function($event){return _vm.$emit('clickMenu', menu.routeName)}}},[_vm._v(" "+_vm._s(menu.label)+" ")])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }