<template>
	<div>
		<div
			class="font-18 mb-5 font-weight-bold"
			:class="{ 'primary--text': 'Home' === routeName }"
			@click="$emit('clickMenu', 'Home')"
		>
			Why Tella
		</div>
		<!-- <a
			href="https://tella.co.kr/tutor"
			style="text-decoration: unset"
			target="_blank"
			@click="$emit('clickMenu', 'Tutor')"
		>
			<div class="mb-5 font-18 font-weight-bold grey--text text--darken-4">
				튜터
			</div>
		</a> -->
		<div
			v-for="menu in menuList"
			:key="menu.routeName"
			class="font-18 mb-5 font-weight-bold"
			:class="{ 'primary--text': menu.routeName === routeName }"
			@click="$emit('clickMenu', menu.routeName)"
		>
			{{ menu.label }}
		</div>
		<!-- <a
			href="https://tella.co.kr/event"
			style="text-decoration: unset"
			target="_blank"
			@click="$emit('clickMenu', 'Event')"
		>
			<div class="mb-5 font-18 font-weight-bold grey--text text--darken-4">
				이벤트
			</div>
		</a> -->

		<!-- <a
			href="https://tella.co.kr/b2b"
			style="text-decoration: unset"
			target="_blank"
			@click="$emit('clickMenu', 'B2B')"
		>
			<div class="mb-5 font-18 font-weight-bold grey--text text--darken-4">
				기업교육
			</div>
		</a> -->
	</div>
</template>

<script>
export default {
	data() {
		return {
			menuList: [
				// { label: 'Why Tella', routeName: 'Home' },
				{ label: '가격', routeName: 'Price' },
				{ label: '프로그램', routeName: 'Program' },
				{ label: '수강후기', routeName: 'Review' },
				{ label: '이벤트', routeName: 'Event' },
				// { label: '기업교육', routeName: 'B2B' },
			],
		}
	},
	computed: {
		routeName() {
			return this.$route.name
		},
	},
}
</script>

<style lang="scss" scoped></style>
