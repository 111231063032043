<template>
	<div id="app-bar-wrap">
		<v-app-bar
			v-resize="onResize"
			:height="barHeight"
			flat
			fixed
			id="app-bar"
			class="elevation-0"
			v-scroll="onScroll"
			:style="isBarHide === true ? 'top:-55px' : ''"
		>
			<!-- : -->
			<!-- -->
			<!-- <v-app-bar-nav-icon
				class="d-block d-sm-none"
				@click="drawer = !drawer"
			></v-app-bar-nav-icon> -->
			<img
				src="@/assets/images/common/menu.png"
				style="width: 45px"
				@click="drawer = !drawer"
				alt=""
				class="mr-1 d-sm-none"
			/>
			<div
				class="d-flex align-center mx-auto"
				style="width: 100%; max-width: 1200px"
			>
				<v-toolbar-title class="px-1" @click="clickMenu('Home')">
					<img width="45" src="@/assets/images/tella_color.png" alt="" />
				</v-toolbar-title>
				<ToolbarMenu @clickMenu="clickMenu" />
				<v-spacer></v-spacer>
				<v-toolbar-items class="align-center">
					<div>
						<v-btn
							href="https://my.tella.co.kr"
							@click="clickLoginBtn"
							color="grey"
							outlined
							class="mr-1"
							:small="userDevice === 'mobile'"
						>
							<span class="grey--text text--darken-2">로그인</span>
						</v-btn>
					</div>
					<div style="position: relative">
						<v-btn
							:small="userDevice === 'mobile'"
							href="https://my.tella.co.kr/trial"
							color="primary"
							depressed
							@click="clickTrialBtn"
						>
							<strong class="font-weight-bold white--text">무료수업</strong>
						</v-btn>
					</div>
				</v-toolbar-items>
			</div>
		</v-app-bar>
		<v-navigation-drawer
			v-model="drawer"
			app
			width="414"
			v-if="userDevice === 'mobile'"
		>
			<div class="px-2 py-2">
				<v-btn
					width="40"
					height="40"
					icon
					@click="drawer = false"
					color="grey darken-3"
					><v-icon>mdi-window-close</v-icon></v-btn
				>
			</div>
			<div class="pl-12 pt-6">
				<img
					@click="clickMenu('Home')"
					width="60"
					src="@/assets/images/tella_color.png"
					alt=""
					class="mb-8"
				/>
				<DrawerMenu @clickMenu="clickMenu" />
			</div>
			<template v-slot:append>
				<div class="px-4 pb-4">
					<!-- <a
						href="https://my.tella.co.kr/trial"
						@click="clickTrialBtn"
						style="text-decoration: unset"
					>
						<v-btn block depressed class="mb-3" color="primary" height="50">
							<div class="font-16 font-weight-medium">무료수업</div>
						</v-btn>
					</a> -->
					<a
						href="https://my.tella.co.kr"
						style="text-decoration: unset"
						@click="clickLoginBtn"
					>
						<v-btn block outlined class="mb-3" color="grey" height="50">
							<div class="font-16 font-weight-medium grey--text text--darken-3">
								로그인
							</div>
						</v-btn>
					</a>
				</div>
			</template>
			<!--  -->
		</v-navigation-drawer>
	</div>
</template>

<script>
import DrawerMenu from './DrawerMenu'
import ToolbarMenu from './ToolbarMenu'

export default {
	components: {
		DrawerMenu,
		ToolbarMenu,
	},
	data() {
		return {
			scrollToUp: false,
			offsetTop: 0,
			windowSize: {
				x: 0,
				y: 0,
			},
			mobileActiveMenu: null,
			isScrolling: false,
			drawer: false,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['userDevice']
		},
		isBarHide() {
			if (this.userDevice === 'desktop') return false
			// 맨 위에 있으면 보여짐
			if (this.offsetTop <= 55) return false
			// 올리면 보여짐
			return this.scrollToUp === true ? false : true
		},
		barHeight() {
			return this.userDevice === 'mobile' ? 55 : 70
		},
		tooltipStyle() {
			const leftPosition =
				this.windowSize.x <= 900
					? 'right: 15px;'
					: 'left: 50%;	margin-left:350px;'
			const topPosition =
				this.userDevice === 'mobile' ? 'top: 55px;' : 'top: 60px;'
			return leftPosition + topPosition
		},
		subMenuStyle() {
			return 'position: sticky; top: 0px; '
		},
	},
	mounted() {
		this.onResize()
	},
	methods: {
		clickTrialBtn() {
			// eslint-disable-next-line no-undef
			gtag('event', 'click_trial_btn', {
				event_label: 'app-bar',
			})
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('click_tellGoToTrial', {
				page: this.$route.name,
				component: 'GNB',
				label: '무료수업',
			})
		},
		clickMenu(routeName) {
			// eslint-disable-next-line no-undef
			gtag('event', 'click_gnb', {
				event_label: routeName,
			})
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('click_tellaGNB', {
				label: routeName,
			})
			this.drawer = false
			if (routeName === 'B2B' || routeName === 'Trial' || routeName === 'Tutor')
				return
			this.$router.push({ name: routeName })
		},
		clickLoginBtn() {
			// todo
			// eslint-disable-next-line no-undef
			gtag('event', 'click_bridge_login')
		},
		onScroll() {
			const oldOffsetTop = this.offsetTop
			this.offsetTop = document.querySelector('html').scrollTop
			this.barShowControl(oldOffsetTop)
			// if (this.$route.name === 'Home') {
			// this.menuActiveControl()
			// this.barShowControl(oldOffsetTop)
			// }
		},
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight }
		},
		// menuActiveControl() {
		// 	let whyTellaEl = document.querySelector('#dont-give-up').offsetTop
		// 	let youCanDoItEl = document.querySelector('#you-can-do-it').offsetTop
		// 	let price = document.querySelector('#price').offsetTop

		// 	this.mobileActiveMenu =
		// 		price < this.offsetTop
		// 			? 'price'
		// 			: youCanDoItEl <= this.offsetTop
		// 			? 'you-can-do-it'
		// 			: whyTellaEl <= this.offsetTop
		// 			? 'dont-give-up'
		// 			: null
		// },
		barShowControl(oldOffsetTop) {
			// this.scrollToDown = false
			if (oldOffsetTop - this.offsetTop > 30) {
				if (this.isScrolling === true) return
				this.scrollToUp = true
			}
			if (oldOffsetTop - this.offsetTop < -10) {
				this.scrollToUp = false
			}
		},
	},
}
</script>

<style scoped lang="scss">
#app-bar {
	transition: top 0.5s ease 0s;
	background-color: #fff;
}
@media screen and (min-width: 600px) {
	#app-bar {
		min-width: 900px;
	}
}
.tooltip {
	position: absolute;
	z-index: 10;
	color: #fff;
	line-height: 1.1em;
	background: #353535;
	border-radius: 10px;
	width: 110px;
	height: 30px;
	font-weight: 700;
	font-size: 12px;
	padding-top: 10px;
	font-weight: bold;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		top: -5px;
		left: 50%;
		margin-left: -5px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;

		border-bottom: 10px solid #353535;
	}
}

.menu-button {
	cursor: pointer;
}
</style>

<style>
#app-bar .v-toolbar__content {
	padding-left: 7px;
}
</style>
