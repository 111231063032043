<template>
	<v-toolbar-items class="ml-5 align-center">
		<div
			@click="$emit('clickMenu', 'Home')"
			large
			:class="{
				'primary--text font-weight-bold': ['Home'].includes(routeName),
				'grey--text text--darken-4': ['Home'].includes(routeName) === false,
			}"
			class="d-none d-sm-flex mx-4 text-body-1 text-center menu-button"
			text
		>
			Why Tella
		</div>
		<!-- <a
			href="https://tella.co.kr/tutor"
			style="text-decoration: unset"
			target="_blank"
			@click="$emit('clickMenu', 'Tutor')"
		>
			<div
				large
				class="grey--text text--darken-4 d-none d-sm-flex mx-4 text-body-1 text-center menu-button"
				text
			>
				튜터
			</div>
		</a> -->
		<div
			v-for="menu in menuList"
			:key="menu.routeName"
			@click="$emit('clickMenu', menu.routeName)"
			large
			:class="{
				'primary--text font-weight-bold': menu.activeList.includes(routeName),
				'grey--text text--darken-4':
					menu.activeList.includes(routeName) === false,
			}"
			class="d-none d-sm-flex mx-4 text-body-1 text-center menu-button"
			text
		>
			{{ menu.label }}
		</div>
		<!-- <a
			href="https://tella.co.kr/b2b"
			style="text-decoration: unset"
			target="_blank"
			@click="$emit('clickMenu', 'B2B')"
		>
			<div
				large
				class="grey--text text--darken-4 d-none d-sm-flex mx-4 text-body-1 text-center menu-button"
				text
			>
				기업교육
			</div>
		</a> -->
	</v-toolbar-items>
</template>

<script>
export default {
	data() {
		return {
			menuList: [
				// { label: 'Why Tella', routeName: 'Home', activeList: ['Home'] },
				// { label: '튜터', routeName: 'Tutor', activeList: ['Tutor'] },
				{ label: '가격', routeName: 'Price', activeList: ['Price'] },
				{
					label: '프로그램',
					routeName: 'Program',
					activeList: ['Program', 'ProgramCategory', 'ProgramDetail'],
				},
				{
					label: '수강후기',
					routeName: 'Review',
					activeList: ['Review'],
				},
				{
					label: '이벤트',
					routeName: 'Event',
					activeList: [
						'Event',
						'Event2022CouponPack',
						'EventFriendRecommend',
						'EventReview',
						'Event2022FromToday',
					],
				},
				// { label: '기업교육', routeName: 'B2B' },
			],
		}
	},
	computed: {
		routeName() {
			return this.$route.name
		},
	},
}
</script>

<style lang="scss" scoped>
.menu-button:hover {
	cursor: pointer;
}
</style>
